body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #131c21;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #394045;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
aside {
  width: 40rem;
}
.green-text {
  color: #25d366;
}
.green-bg {
  color: #25d366;
}
.green-border {
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg, #7D4196 0%, #FF3494 100%);
}
.main-header,
.aside-header {
  background-color: #2a2f32;
}
.main-footer {
  background-color: #1e2428;
}
.search-bar,
.message, .aside-messages {
  background-color: #131c21;
}
.search-bar input,
.message-active {
  background-color: #323739;
}
.single-message {
  background-color: #262d31;
}
.single-message.user {
  background-color: #474193;
}
.incoming-svg {
  color: #262d31;
  transform: scaleX(-1);
}
.user-svg {
  color: #474193;
}
.message-input {
  background-color: #33383b;
}
.bg-whatsapp {
  background-color: #0d1418;
  background-image: url(https://amplify-famouscoach-production-05150-deployment.s3.us-east-2.amazonaws.com/chatbg.png);
  background-size: 250px;
  /* background-position: top; */
  background-repeat: repeat;
}
.main-messages {
  background: #0e1921f0;
}

.main-messages-loading {
  background: #0e1921f0;
}