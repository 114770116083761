@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");

body {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  background-color: white;
}

.full-screen-wrapper {
  min-height: 100vh;
  width: 100%;
  background-color: white;
}

.header {
  background-color: white;
  border-bottom: 1px solid #dbdbdb;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-content {
  max-width: 935px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  font-weight: bold;
  font-size: 20px;
  color: black;
}

.open-in-app-button {
  background-color: #0095f6;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 600;
}

.container {
  max-width: 935px;
  margin: 0 auto;
  padding: 30px 20px;
}

.profile-section {
  display: flex;
  margin-bottom: 2rem;
  align-items: flex-start;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-right: 2rem;
  object-fit: cover;
}

.user-info {
  flex: 1;
}

.user-name-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.user-name {
  font-size: 28px;
  font-weight: bold;
  margin-right: 20px;
  margin-bottom: 10px;
}

.action-buttons {
  display: flex;
}

.action-button {
  padding: 7px 16px;
  border-radius: 4px;
  font-weight: 600;
  margin-right: 10px;
  background-color: #efefef;
  color: black;
  border: none;
  cursor: pointer;
}

.stats-container {
  display: flex;
  margin-bottom: 20px;
}

.stat-item {
  margin-right: 40px;
}

.stat-value {
  font-weight: bold;
}

.bio {
  margin-bottom: 2rem;
}

.bio-name {
  font-weight: 600;
}

.tabs-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e0e0e0;
}

.active-tab {
  border-bottom: 2px solid #3b82f6;
  padding-bottom: 0.5rem;
}

.character-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  position: relative;
  padding-bottom: 150px;
}

.character-card {
  position: relative;
  padding-bottom: 150%;
  overflow: hidden;
}

.character-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.character-card:hover .character-image {
  transform: scale(1.05);
}

.blurred-image {
  filter: blur(20px);
}

.character-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  padding-top: 5rem;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  color: white;
}

.character-name {
  margin: 0 0 0.25rem 0;
  font-size: 1.6rem;
  font-weight: 700;
  color: #ffffff;
}

.character-bio {
  margin: 0 0 0.25rem 0;
  font-size: 0.9rem;
  color: #ffffff;
}

.chat-count {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}

.chat-count svg {
  margin-right: 0.25rem;
}

.nsfw-tag {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: #ff00ff;
  color: white;
  padding: 0.3rem 0.6rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 700;
}

.gradient-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 30%,
    rgba(255, 255, 255, 1) 100%
  );
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 20px;
}

.open-in-app-text {
  color: #0095f6;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: white;
}

.error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: white;
  color: red;
  font-weight: bold;
}

@media (max-width: 935px) {
  .header-content,
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 768px) {
  .profile-section {
    flex-direction: column;
    align-items: center;
  }

  .profile-image {
    width: 100px;
    height: 100px;
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .user-name-section {
    flex-direction: column;
    align-items: center;
  }

  .user-name {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .action-buttons {
    margin-bottom: 1rem;
  }

  .stats-container {
    justify-content: space-around;
  }

  .stat-item {
    margin-right: 0;
  }

  .character-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .tab-text {
    display: none;
  }

  .tabs-container {
    justify-content: space-between;
  }

  .tabs-container > div {
    padding: 10px;
  }
}

@media (max-width: 350px) {
  .action-buttons {
    flex-direction: column;
  }

  .action-button {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
